import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "utilisation-du-back-office"
    }}>{`Utilisation du back-office`}</h1>
    <h2 {...{
      "id": "utilisation-des-constantes-paramétrables"
    }}>{`Utilisation des constantes paramétrables`}</h2>
    <p>{`Le `}<a parentName="p" {...{
        "href": "http://api.galidog.org/"
      }}>{`back-office`}</a>{` du projet offre la possibilité de modifier à tout moment les constantes utilisées au sein de l'application mobile.`}</p>
    <p>{`Ainsi, il s'agit de `}<strong parentName="p">{`valeurs dynamiques`}</strong>{` qui ont une influence directe sur le comportement de l'application, notamment lors d'une navigation/mémorisation/restitution de trajet.`}</p>
    <table>
  <tr>
    <th style={{
          "textAlign": "center"
        }}>Constante</th>
    <th style={{
          "textAlign": "center"
        }}>Description</th>
    <th style={{
          "textAlign": "center"
        }}>Valeur par défaut (recommandé)</th>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>NAVIGATING_WATCH_POSITION_TIME_INTERVAL</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Exprimée en <span style={{
            "color": "red"
          }}>milliseconds</span>, cette option est utilisée pour le mode navigation (lors de la recherche d'un itinéraire entre un point A et un point B) pour calculer la bonne position actuelle de l'utilisateur.
      <br /><br />
      Il s'agit de l'option <span style={{
            "color": "red"
          }}>timeInterval</span> utilisée lors de l'appel à la fonction <a href="https://docs.expo.io/versions/latest/sdk/location/#locationwatchpositionasyncoptions-callback">watchPositionAsync</a>.
      Cela permet de définir le temps minimum à attendre avant de recevoir chaque mise à jour de la position courante de l'utilisateur.
      <br /><br />
      <em>
        Ex: 5000 signifierait devoir attendre 5 secondes avant de recevoir une actualisation de la position courante de l'utilisateur
      </em>.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>1000</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>KALMAN_VELOCITY</td>
    <td>
      Cette option est <span style={{
            "color": "red"
          }}>utilisée lors de la restitution d'un trajet</span>.
      <br /><br />
      Cela permet de régler dynamiquement la vélocité utilisée dans l'application de la formule du filtre de <a href="https://fr.wikipedia.org/wiki/Filtre_de_Kalman">Kalman</a>.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>1000</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>KALMAN_ENABLE</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Cette option est <span style={{
            "color": "red"
          }}>utilisée lors de la restitution d'un trajet</span>.
      <br /><br />
      Cela permet d'activer ou de désactiver le filtre de <a href="https://fr.wikipedia.org/wiki/Filtre_de_Kalman">Kalman</a>. 
      Ce filtre permet d'obtenir une meilleure précision des points enregistrés lors du mode mémorisation.
      <br /><br />
      Les valeurs possibles sont 0 (désactivé) ou 1 (activé).
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>1</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>KALMAN_RANGE_PRECISION</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Cette option est <span style={{
            "color": "red"
          }}>utilisée lors de la restitution d'un trajet</span>.
      <br /><br />
      Cela permet de régler dynamiquement la précision utilisée dans l'application de la formule du filtre de <a href="https://fr.wikipedia.org/wiki/Filtre_de_Kalman">Kalman</a>.
      <br /><br />
      Les valeurs possibles doivent être comprises entre 0 et 2000.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>1000</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>ACCURACY_THRESHOLD</td>
    <td style={{
          "textAlign": "justify"
        }}>
        Cette option est <span style={{
            "color": "red"
          }}>utilisée lors de la restitution d'un trajet, de l'enregistrement d'un trajet (mémorisation) et lors du calcul d'un itinéraire (mode navigation)</span>.
        <br /><br />
        Cela permet de prendre en compte <span style={{
            "color": "red"
          }}>uniquement</span> les positions de l'utilisateur ayant un accuracy inférieur à cette valeur.
        <br /><br />
        <em>
          Plus l'accuracy est élévé, plus la précision de la position est faible.
        </em>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>25</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>HAVERSINE_THRESHOLD_NEARBY</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Cette option est <span style={{
            "color": "red"
          }}>utilisée lors de la restitution d'un trajet</span>.
      <br /><br />
      Exprimée en <span style={{
            "color": "red"
          }}>mètres</span>, cette valeur permet de définir le threshold à utiliser pour annoncer l'instruction « <span style={{
            "color": "red"
          }}>prochainement</span> » d'un point.
      Ce threshold est utilisé lors de l'application de la formule <a href="https://fr.wikipedia.org/wiki/Formule_de_haversine">d'haversine</a>.
      <br /><br />
      <em>
        Ex: si l'utilisateur se situe dans un périmètre de N mètres autour du prochain point à atteindre, l'instruction « prochainement [...] » sera alors annoncée.  
      </em>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>15</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>HAVERSINE_THRESHOLD_REACHED</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Cette option est <span style={{
            "color": "red"
          }}>utilisée lors de la restitution d'un trajet</span>.
      <br /><br />
      Exprimée en <span style={{
            "color": "red"
          }}>mètres</span>, cette valeur permet de définir le threshold à utiliser pour annoncer l'instruction « <span style={{
            "color": "red"
          }}>maintenant</span> » d'un point.
      Ce threshold est utilisé lors de l'application de la formule <a href="https://fr.wikipedia.org/wiki/Formule_de_haversine">d'haversine</a>.
      <br /><br />
      <em>
        Ex: si l'utilisateur se situe dans un périmètre de N mètres autour du prochain point à atteindre, l'instruction « maintenant [...] » sera alors annoncée.  
      </em>
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>6</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>REPLAYING_WATCH_POSITION_TIME_INTERVAL</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Même rôle que NAVIGATING_WATCH_POSITION_TIME_INTERVAL, mais pour <span style={{
            "color": "red"
          }}>le mode rejouer un trajet</span>.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>1000</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>RECORDING_WATCH_POSITION_TIME_INTERVAL</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Même rôle que NAVIGATING_WATCH_POSITION_TIME_INTERVAL, mais pour <span style={{
            "color": "red"
          }}>le mode enregistrer un trajet (mémorisation)</span>.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>1000</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>NUMBER_WAITING_GOOD_ACCURACY</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Cela permet de déterminer si la position de l'utilisateur respecte ACCURACY_THRESHOLD <span style={{
            "color": "red"
          }}>lors la récupération de la bonne première position de l'utilisateur</span>.
      Ainsi, si le système comprend que N bonnes valeurs du compass sont récupérées, alors il en déduit que la valeur du compass est fiable et que c'est celle-ci qu'il faut retenir.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>3</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>MINIMUM_THRESHOLD_BATTERY_PERCENTAGE</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Exprimée en <span style={{
            "color": "red"
          }}>pourcentage</span>, il s'agit de la valeur en dessous de laquelle l'utilisateur est alerté de la <span style={{
            "color": "red"
          }}>batterie faible du téléphone</span>.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>10</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>MAX_STORED_POSITIONS_FOR_AZIMUT</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Valeur représentant <span style={{
            "color": "red"
          }}>le nombre de positions</span> qu'on juge nécessaire pour effectuer <span style={{
            "color": "red"
          }}>le calcul d’azimut</span>.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>4</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>WALK_COUNT_FOR_ORIENTATION</td>
    <td style={{
          "textAlign": "justify"
        }}>
      <span style={{
            "color": "red"
          }}>Nombre de mesures</span> avant la validation du processus de <span style={{
            "color": "red"
          }}>calibrage de l’orientation</span>, souvent lié à 1 seconde par mesures gps.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>5</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>NEXT_POINT_DISTANCE_SPEECH_INTERVAL</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Exprimée en <span style={{
            "color": "red"
          }}>mètres</span>, il s'agit de l'intervalle de distance à laquelle est « speechée » la prochaine instruction.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>15</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>MINIMUM_THRESHOLD_HARNESS_BATTERY_PERCENTAGE</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Exprimée en <span style={{
            "color": "red"
          }}>pourcentage</span>, il s'agit de la valeur en dessous de laquelle l'utilisateur est alerté de <span style={{
            "color": "red"
          }}>la batterie faible du harnais</span> Galidog.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>10</td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>ACCURACY_DEGRADATION_ZONE_TIME_DELAY</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Exprimée en <span style={{
            "color": "red"
          }}>milliseconds</span>, il s'agit de la valeur pour <span style={{
            "color": "red"
          }}>la durée</span> avant laquelle on considère qu'une personne est ressortie d'une zone de dégradation d'accuracy.
    </td>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>5000</td>
  </tr>
    </table>
    <h2 {...{
      "id": "utilisation-des-harnais-connectés"
    }}>{`Utilisation des harnais connectés`}</h2>
    <p>{`Cette section permet de gérer les différents `}<strong parentName="p">{`harnais`}</strong>{` Galidog qui seront utilisés au sein de l'application mobile via la technologie `}<strong parentName="p">{`bluetooth`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`IMPORTANT : l'application mobile ne sera capable de détecter QUE les harnais référencés dans cette section du BO lors du scan bluetooth !`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Chaque harnais est identifié grâce à `}<strong parentName="p">{`son identifiant unique`}</strong>{` !`}</p>
    </blockquote>
    <p>{`Un harnais Galidog comporte :`}</p>
    <ul>
      <li parentName="ul">{`4 capteurs de vibration (1 « gros » et 1 « petit » de chaque côté)`}</li>
      <li parentName="ul">{`des enceintes pour jouer du son`}</li>
    </ul>
    <p>{`Actuellement, le rôle du harnais connecté avec l'application mobile est de transmettre des vibrations que le chien sera capable d'interpréter afin de guider le DV (le harnais est attaché au chien).
Par exemple, une vibration seule à droite indiquera au chien qu'il faut tourner à droite. `}</p>
    <blockquote>
      <p parentName="blockquote">{`Chaque chien est éduqué pour cette pratique et est donc capable d'interpréter les vibrations du harnais !`}</p>
    </blockquote>
    <p>{`Il existe actuellement 4 directives possibles :`}</p>
    <ul>
      <li parentName="ul">{`droite (les capteurs de vibration de `}<strong parentName="li">{`DROITE`}</strong>{` seront uniquement concernés)`}</li>
      <li parentName="ul">{`gauche (les capteurs de vibration de `}<strong parentName="li">{`GAUCHE`}</strong>{` seront uniquement concernés)`}</li>
      <li parentName="ul">{`halte (les capteurs de vibration de `}<strong parentName="li">{`DROITE ET GAUCHE`}</strong>{` seront concernés)`}</li>
      <li parentName="ul">{`lignes (les capteurs de vibration de `}<strong parentName="li">{`DROITE ET GAUCHE`}</strong>{` seront concernés)`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Chaque directive peut être accompagnée d'un son si l'option en question est activée pour le harnais.`}</p>
    </blockquote>
    <p>{`Chaque directive peut donc être configurée de la manière suivante :`}</p>
    <table>
  <tr>
    <th style={{
          "textAlign": "center"
        }}>Paramètre</th>
    <th style={{
          "textAlign": "center"
        }}>Description</th>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>POURCENTAGE DE VIBRATION</td>
    <td style={{
          "textAlign": "justify"
        }}>
      En d'autres termes, il s'agit de la puissance de la vibration émise. Cette valeur doit être supérieure à <span style={{
            "color": "red"
          }}>40%</span> pour que le harnais soit capable d'enclencher une vibration.
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>DUREE DE VIBRATION</td>
    <td style={{
          "textAlign": "justify"
        }}>
      Exprimée en secondes, cette valeur détermine combien de temps les capteurs doivent vibrer.
    </td>
  </tr>
  <tr>
    <td style={{
          "textAlign": "center",
          "verticalAlign": "middle"
        }}>TYPE DE VIBRATION</td>
    <td style={{
          "textAlign": "justify"
        }}>
      3 valeurs sont possibles : <span style={{
            "color": "red"
          }}>1</span> pour activer les gros capteurs ; <span style={{
            "color": "red"
          }}>2</span> pour activer les petits capteurs ; <span style={{
            "color": "red"
          }}>3</span> pour activer les gros et petits capteurs en même temps.
    </td>
  </tr>
    </table>
    <h2 {...{
      "id": "fichier-adresse"
    }}>{`Fichier adresse`}</h2>
    <h3 {...{
      "id": "création-du-fichier-adresse"
    }}>{`Création du fichier adresse`}</h3>
    <ol>
      <li parentName="ol">{`Recherchez votre adresse d’arrivée sur Google Maps :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/search-navigation.png")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Un point rouge apparaît sur la carte Google Maps :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/pin-point-google-maps.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Faites un clic droit sur l’icône de localisation. Une fenêtre apparaît :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/google-maps-coordinates.png")} /></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Copiez les coordonnées de localisation :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/google-maps-coordinates-red-border.png")} /></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Ouvrez votre application bloc note pour créer votre fichier adresse. Collez les coordonnées :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/coordinates-bloc-note.png")} /></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`La mise en forme du trajet est la suivante :`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Insérez le nom du trajet au début`}</li>
      <li parentName="ul">{`Ne laissez que 6 chiffres après la virgule`}</li>
      <li parentName="ul">{`Séparez chaque élément par un point virgule`}</li>
      <li parentName="ul">{`Supprimez les espaces`}</li>
    </ul>
    <p>{`Vous pouvez indiquer plusieurs adresses. Assurez-vous de respecter le format :`}</p>
    <p><img alt="Screenshot" src={require("./assets/images/addresses-file-format.png")} /></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Enregistrez votre document sur votre ordinateur « Fichier adresses ».`}</li>
    </ol>
    <h3 {...{
      "id": "importer-le-fichier-adresse-dans-le-back-office"
    }}>{`Importer le fichier adresse dans le back-office`}</h3>
    <ol>
      <li parentName="ol">{`Connectez-vous au back-office :`}</li>
    </ol>
    <p>{`Vous voyez la liste des utilisateurs. `}</p>
    <p>{`Que vous créiez votre utilisateur ou le modifiez, vous verrez un champ « Fichier adresses ».`}</p>
    <p>{`Cliquez sur « Choisir un fichier » dans l’encadré rouge :`}</p>
    <p><img alt="Screenshot" src={require("./assets/images/choose-addresses-file-from-bo.png")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Il vous suffit d’insérer votre fichier et de sauvegarder les modifications à l'aide du bouton en bas à droite :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/save-addresses-file-from-bo.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`Le fichier adresses peut être importé par les SUPER ADMINS et les ADMINS.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`En revanche, les admins, eux, peuvent seulement éditer ce fichier et non pas les autres champs d’un profil utilisateur.`}</p>
    </blockquote>
    <h3 {...{
      "id": "modifier-votre-fichier-adresse-et-le-réimporter-dans-le-back-office"
    }}>{`Modifier votre fichier adresse et le réimporter dans le back-office`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Si votre fichier adresse n’est pas dans le fichier document de votre ordinateur, récupérez-le dans le back-office.`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">{`Retrouvez l’utilisateur et consultez son profil :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/show-user-profile-from-bo.png")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Cliquez sur le fichier adresses :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/see-addresses-file-from-bo.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Un fichier adresses s’ouvre dans une nouvelle fenêtre :`}</li>
    </ol>
    <p>{`Faîtes un clic droit dans le fichier et cliquez sur « Enregistrez sous » :`}</p>
    <p><img alt="Screenshot" src={require("./assets/images/save-in-computer-addresses-file-from-bo.png")} /></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Votre fichier est désormais dans vos documents :`}</li>
    </ol>
    <p>{`Ouvrez-le pour le modifier.`}</p>
    <p>{`Il vous suffit ensuite de l’importer en suivant la procédure décrite ci-dessus.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Le fichier adresses peut être modifié par les SUPER ADMINS et les ADMINS.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`En revanche, les admins, eux, peuvent seulement éditer ce fichier et non pas les autres champs d’un profil utilisateur.`}</p>
    </blockquote>
    <h3 {...{
      "id": "télécharger-le-fichier-adresse-sur-le-téléphone-dun-utilisateur"
    }}>{`Télécharger le fichier adresse sur le téléphone d’un utilisateur`}</h3>
    <ol>
      <li parentName="ol">{`Sur l’écran d’accueil de l’application, allez sur l’onglet « Réglages » :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/click-settings-from-app-homepage.jpeg")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Une fois dans l’onglet, scrollez et cliquez sur « Fichier d’adresses » :`}</li>
    </ol>
    <p><img alt="Screenshot" src={require("./assets/images/click-addresses-file-from-app-settings.jpeg")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Une pop up vous alerte du téléchargement du fichier :`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Attention : cela peut prendre un peu de temps !`}</p>
    </blockquote>
    <p><img alt="Screenshot" src={require("./assets/images/popup-addresses-file-app.jpeg")} /></p>
    <h3 {...{
      "id": "synchroniser-avec-le-téléphone"
    }}>{`Synchroniser avec le téléphone`}</h3>
    <p>{`Si vous avez pas préalablement téléchargé le fichier adresse et que vous recliquez dessus, celui-ci sera mis à jour.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Attention : cela peut prendre un peu de temps !`}</p>
    </blockquote>
    <p><img alt="Screenshot" src={require("./assets/images/sync-addresses-file-from-app.jpeg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      